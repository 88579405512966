/* eslint-disable @typescript-eslint/naming-convention */

import { Injectable } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { Store } from '@ngxs/store';
import type { Attachment, WorkOrder, WorkOrderLine } from '@tag/graphql';
import { Apollo } from 'apollo-angular';
import { Observable, forkJoin, from, of } from 'rxjs';
import { concatAll, take, tap } from 'rxjs/operators';

import { AddFeedback } from '@stores-actions/feedback.action';
import { AuthState } from '@stores-states/authentication.state';
import { FeedbackStateObject } from '@stores-states/feedback.state';
import { AttachmentsStateService } from '@app/stores/signals/attachments-state.service';
import { DocStorDocumentType } from '@api/types';
import { ApiService } from '@services/api.service';

@Injectable({
  providedIn: 'root',
})
export class MaintenanceService {
  constructor(
    private attachmentsStateService: AttachmentsStateService,
    private readonly store: Store,
    private translocoService: TranslocoService,
    private api: ApiService,
    private apollo: Apollo
  ) {}

  get company(): string {
    return this.store.selectSnapshot(AuthState.company);
  }

  addFeedback(
    workOrderLine: WorkOrderLine,
    feedback: string,
    type: string,
    failureCode: string,
    workOrder?: WorkOrder
  ): Observable<FeedbackStateObject | undefined> {
    if (!workOrderLine || !feedback) return of(undefined);
    const fb: Partial<FeedbackStateObject> = {
      id: '',
      type: 'feedback',
      tableName: 'OrderLine',
      documentType: 'Released',
      documentLineNo: workOrder ? 0 : workOrderLine.lineNo,
      feedbackType: type,
      failureCode: failureCode,
      date: new Date(),
      technicianCode: this.store.selectSnapshot(AuthState.technician) || '',
      feedback,
      documentNo: workOrder ? workOrder.no : workOrderLine.workOrderNo,
      equipmentId: workOrder
        ? workOrder.equipmentId
        : workOrderLine.equipmentId,
    };
    return this.store.dispatch(new AddFeedback(fb as any));
  }

  /**
   *
   * @param documentNo
   * @param lineNo
   * @param sourceType
   * @param attachment
   * @deprecated use addAttachments instead
   * @returns
   */
  addAttachment(
    documentNo: string,
    lineNo: number,
    sourceType: string,
    attachment: File
  ): Observable<Attachment> {
    const obs$ = this.getAttachmentPayload([
      {
        documentNo,
        lineNo,
        sourceType: sourceType as DocStorDocumentType,
        attachment,
      },
    ])[0];

    return obs$.pipe(
      take(1),
      tap((att) => {
        this.attachmentsStateService.refreshAll();
        this.apollo.client.cache.evict({
          id: 'ROOT_QUERY',
          fieldName: 'attachments',
        });
      })
    );
  }

  /**
   *
   * @param attachments
   * @returns
   */
  addAttachments(
    attachments: {
      documentNo: string;
      lineNo: number;
      fileName?: string;
      sourceType: DocStorDocumentType;
      facility?: string;
      advancedAttachmentType?: string;
      cardPicture?: boolean;
      description?: string;
      attachment?: File;
    }[],
    isDocStor: boolean
  ): Observable<Attachment[]> {
    const attachmentPayload = this.getAttachmentPayload(attachments, isDocStor);

    return forkJoin([
      from(attachmentPayload).pipe(concatAll(), take(attachmentPayload.length)),
    ]).pipe(
      take(1),
      tap((att) => {
        this.attachmentsStateService.refreshAll();
        this.apollo.client.cache.evict({
          id: 'ROOT_QUERY',
          fieldName: 'attachments',
        });

        this.apollo.client.cache.evict({
          id: 'ROOT_QUERY',
          fieldName: 'docStorAttachments',
        });
      })
    );
  }

  /**
   *
   * @param attachments
   * @returns attachmentPayload as Observable<Attachment>[]
   */
  private getAttachmentPayload(
    attachments: {
      documentNo: string;
      lineNo: number;
      fileName?: string;
      sourceType: DocStorDocumentType;
      facility?: string;
      advancedAttachmentType?: string;
      description?: string;
      cardPicture?: boolean;
      attachment?: File;
    }[],
    isDocStor = false
  ): Observable<Attachment>[] {
    return attachments.map((attachment) => {
      let type = '';
      switch (attachment.sourceType) {
        case 'WorkOrder':
          type = 'Work Order';
          break;
        case 'WorkOrderLine':
          type = 'Work Order';
          break;
        case 'FinishedWorkOrder':
          type = 'Work Order';
          break;
        case 'FinishedWorkOrderLine':
          type = 'Line';
          break;
        case 'PlannedWorkOrder':
          type = 'Work Order';
          break;
        case 'PlannedWorkOrderLine':
          type = 'Line';
          break;
        default:
          type = attachment.sourceType;
          break;
      }

      const form = new FormData();
      if (attachment.attachment) form.append('file', attachment.attachment);
      if (attachment.fileName) form.append('file_Name', attachment.fileName);
      form.append('source_Type', isDocStor ? attachment.sourceType : type);
      form.append('no', attachment.documentNo);
      form.append('no_Line_No', attachment.lineNo.toString());
      form.append('card_picture', attachment.cardPicture ? 'true' : 'false');
      form.append(
        'advanced_Attachment_Type',
        attachment.advancedAttachmentType || 'None'
      );
      form.append('description', attachment.description || '');
      if (attachment.facility) form.append('facility', attachment.facility);

      return this.api.post(
        isDocStor ? '/attachments/docstash' : '/attachments',
        form
      );
    });
  }
}
