import { CommonModule } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { TranslocoModule, TranslocoService } from '@ngneat/transloco';

import { DialogData } from '@models/dialog-data';

const lineFeed = '\n';

@Component({
  standalone: true,
  imports: [CommonModule, TranslocoModule, MatButtonModule, MatIconModule],
  selector: 'app-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.css'],
})
export class ConfirmDialogComponent implements OnInit {
  labelToDisplay = '';
  showWarningIcon = true;

  constructor(
    public dialogRef: MatDialogRef<ConfirmDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogData: DialogData,
    private translocoService: TranslocoService
  ) {}

  /** LineFeed is used to show message on several lines*/
  /** Line can be translated if it is a key only */
  ngOnInit(): void {
    const labels = this.dialogData.data.split(lineFeed);
    labels.forEach((label: string, i: number) => {
      if (label.length < 3 || label.slice(-3) !== 'Key') this.labelToDisplay += label;
      else this.labelToDisplay += this.translocoService.translate(label);
      if (i < labels.length - 1) this.labelToDisplay += lineFeed;
    });
    this.showWarningIcon = this.dialogData.showWarningIcon ?? true;
  }

  closeDialog(value: boolean): void {
    this.dialogRef.close(value);
  }
}
